import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/MainLayout";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
// components
import LoadingScreen from "../components/LoadingScreen";
import ProtectedRoute from "./ProtectedRoute";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <MainLayout />
        </ProtectedRoute>
      ),
      children: [
        {path: '', element: <Home />},
        {path: 'proveedores', element: <ProvidersListNew />},
        {path: 'clientes', element: <ClientsListNew />},
        {path: 'Empleados', element: <WorkersListNew />},
        {path: 'productos', element: <ProductsAndCategoriesListNew />},
        {path: 'productos/eliminar/:id', element: <ProductDeleteNew />},
        {path: 'productos/eliminar-categoria/:id', element: <CategoryDeleteNew />},
        ]
    },
    {path: '/proveedores/registrar', element:  <ProtectedRoute><ProvidersRegisterNew /></ProtectedRoute>},
    {path: '/proveedores/editar/:id', element: <ProtectedRoute><ProvidersEditNew /></ProtectedRoute>},
    {path: '/proveedores/eliminar/:id', element: <ProtectedRoute><ProvidersDeleteNew /></ProtectedRoute>},

    {path: '/clientes/registrar', element: <ProtectedRoute><ClientsRegisterNew /></ProtectedRoute> },
    {path: '/clientes/editar/:id', element: <ProtectedRoute><ClientsEditNew /></ProtectedRoute> },
    {path: '/clientes/eliminar/:id', element: <ProtectedRoute><ClientsDeleteNew /></ProtectedRoute> },

    {path: '/Empleados/registrar', element: <ProtectedRoute><WorkerRegisterNew /></ProtectedRoute>},
    {path: '/Empleados/editar/:id', element: <ProtectedRoute><WorkerEditNew /></ProtectedRoute>},
    {path: '/Empleados/eliminar/:id', element: <WorkerDeleteNew />},

  

    {path: '/productos/registrar', element: <ProtectedRoute><ProductRegisterNew /></ProtectedRoute>},
    {path: '/productos/editar/:id', element: <ProtectedRoute><ProductEditNew /></ProtectedRoute>},
    {path: '/productos/registrar-categoria', element: <ProtectedRoute><CategoryRegisterNew /></ProtectedRoute>},
    {path: '/productos/editar-categoria/:id', element: <ProtectedRoute><CategoryEditNew /></ProtectedRoute>},


    
    {
      path: '/vender',
      element: <ProtectedRoute><MainLayout /></ProtectedRoute> ,
      children: [
        {path: '', element: <SellProductsAndCategoriesNew /> },
        {path: ':id', element: <SellProductDetailNew />},
        {path: 'venta-exitosa', element: <SuccessSaleNew />},
        {path: 'comprobante/:id', element: <VocherNew />}
       
      ]
    },
    {path: '/resumen-de-compra', element: <ProtectedRoute><SellProductsCartNew /></ProtectedRoute> },

    
    {
      path: '/catalogo',
      element: <ProtectedRoute><MainLayout /></ProtectedRoute>,
      children: [
        {path: '', element: <CatalogueNew /> },
        {path: ':id', element: <CatalogueProductDetailNew />},
        {path: 'ventas-exitosas', element: <CatalogueSuccessSaleNew />},

      ]
    },
    {path: '/resumen-de-compras', element: <ProtectedRoute><CatalogueProductsCartNew /></ProtectedRoute> },

    
   
    {
      path: '/pedidos',
      element: <ProtectedRoute><MainLayout /></ProtectedRoute> ,
      children: [
        {path: '', element: <OrdersListNew /> },
        {path: 'eliminar/:id', element: <OrderDeleteNew /> },
      ]
    },
   
    {
      path: '/pedidosCliente/:id',
      element: <ProtectedRoute><MainLayout /></ProtectedRoute> ,
      children: [
        {path: '', element: <OrdersListClientNew /> },
      ]
    },
    {path: '/pedidosCliente/detalle/:id', element: <ProtectedRoute><OrderDetailClientNew /></ProtectedRoute> },
    {path: '/pedidosCliente/detalle/:id/productos/:accion', element: <ProtectedRoute><OrderProductsNew /></ProtectedRoute> },
    {
      path: '/estadisticas',
      element: <ProtectedRoute><MainLayout /></ProtectedRoute> ,
      children: [
        {path: '', element: <StadisticsResume /> },
      ]
    },

    {path: '/estadisticas/filtros', element: <ProtectedRoute><StadisticsFilters /></ProtectedRoute>  },
    

    {path: '/pedidos/:id', element: <ProtectedRoute><OrderDetailNew /></ProtectedRoute> },
    {path: '/pedidos/:id/productos/:accion', element: <ProtectedRoute><OrderProductsNew /></ProtectedRoute> },
    {path: '/pedidos/:id/recibo', element: <ProtectedRoute><OrderReceiptNew /></ProtectedRoute> },

   
    {path: '/login', element: <Login /> },
    {path: '/recuperar-clave', element: <ForgotPassword /> },
    {path: '/reestablecer-clave', element: <ResetPassword /> },
    {path: '/login-auth', element: <LoginAuth /> },
    {path: '/sign', element: <Sign /> },
  ])
}

// ----------------------------------------------------------------------

const NotFound = Loadable(lazy(() => import('../pages/NotFound')));
const Home = Loadable(lazy(() => import('../pages/home')));

//providers
const ProvidersListNew = Loadable(lazy(() => import('../pages/providers/ProvidersListNew')));
const ProvidersRegisterNew = Loadable(lazy(() => import('../pages/providers/ProvidersRegisterNew')));
const ProvidersEditNew = Loadable(lazy(() => import('../pages/providers/ProvidersEditNew')));
const ProvidersDeleteNew = Loadable(lazy(() => import('../pages/providers/ProvidersDeleteNew')));

//clients
const ClientsListNew = Loadable(lazy(() => import('../pages/clients/ClientsListNew')));
const ClientsRegisterNew = Loadable(lazy(() => import('../pages/clients/ClientsRegisterNew')));
const ClientsEditNew = Loadable(lazy(() => import('../pages/clients/ClientsEditNew')));
const ClientsDeleteNew = Loadable(lazy(() => import('../pages/clients/ClientsDeleteNew')));

//workers
const WorkersListNew = Loadable(lazy(() => import('../pages/workers/WorkersListNew')));
const WorkerEditNew = Loadable(lazy(() => import('../pages/workers/WorkerEditNew')));
const WorkerDeleteNew = Loadable(lazy(() => import('../pages/workers/WorkerDeleteNew')));
const WorkerRegisterNew = Loadable(lazy(() => import('../pages/workers/WorkerRegisterNew')));


//product new
const ProductsAndCategoriesListNew = Loadable(lazy(() => import('../pages/products/indexNew')));
const ProductRegisterNew = Loadable(lazy(() => import('../pages/products/ProductRegisterNew')));
const ProductEditNew = Loadable(lazy(() => import('../pages/products/ProductEditNew')));
const ProductDeleteNew = Loadable(lazy(() => import('../pages/products/ProductDeleteNew')));
const CategoryRegisterNew = Loadable(lazy(() => import('../pages/products/CategoryRegisterNew')));
const CategoryEditNew = Loadable(lazy(() => import('../pages/products/CategoryEditNew')));
const CategoryDeleteNew = Loadable(lazy(() => import('../pages/products/CategoryDeleteNew')));


//sellNew
const SellProductsAndCategoriesNew = Loadable(lazy(() => import('../pages/sells/indexNew')));
const SellProductDetailNew = Loadable(lazy(() => import('../pages/sells/ProductDetailForSaleNew')));
const SellProductsCartNew = Loadable(lazy(() => import('../pages/sells/CartNew')));
//const EmptyCartNew = Loadable(lazy(() => import('../pages/sells/EmptyCartNew')));
const SuccessSaleNew = Loadable(lazy(() => import('../pages/sells/SuccessSaleNew')));
const VocherNew = Loadable(lazy(() => import('../pages/sells/VoucherNew')));



//catalogue new
const CatalogueNew = Loadable(lazy(() => import('../pages/catalogue/indexCNew')));
const CatalogueProductDetailNew = Loadable(lazy(() => import('../pages/catalogue/ProductDetailForSaleCNew')));
const CatalogueProductsCartNew = Loadable(lazy(() => import('../pages/catalogue/CartCNew')));
const CatalogueSuccessSaleNew = Loadable(lazy(() => import('../pages/catalogue/SuccessSaleCNew')));


//ordersNew
const OrdersListNew = Loadable(lazy(() => import('../pages/orders/OrdersListNew')));
const OrdersListClientNew = Loadable(lazy(() => import('../pages/orders/OrdersListClientNew')));
const OrderDetailNew = Loadable(lazy(() => import('../pages/orders/OrderDetailNew')));
const OrderDetailClientNew = Loadable(lazy(() => import('../pages/orders/OrderDetailClientNew')));
const OrderProductsNew = Loadable(lazy(() => import('../pages/orders/OrderProductsNew')));
const OrderReceiptNew = Loadable(lazy(() => import('../pages/orders/OrderReceiptNew')));
const OrderDeleteNew = Loadable(lazy(() => import('../pages/orders/OrderDeleteNew')));

//stadistics
const StadisticsResume = Loadable(lazy(() => import('../pages/stadistics/index')))
const StadisticsFilters = Loadable(lazy(() => import('../pages/stadistics/StadisticsFilters')))

//auth
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const LoginAuth = Loadable(lazy(() => import('../pages/auth/LoginAuth')));
const Sign = Loadable(lazy(() => import('../pages/auth/Sign')));


