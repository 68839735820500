import { useState,useEffect } from "react";

import axios from "../../../utils/axios";

export const useGetMenuNew = () =>{
    
    const [getMenuData,SetGetMenuData] = useState([])
    const[error,SetError] = useState(null)
    const[isGetMenuLoading,SetIsGetMenuLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                  const response = await axios.post("/menu");
              
                if(response){
                   
                   SetGetMenuData(response.data)
                } 
                
                
            }
            catch(error){
                SetError(error.message)
            }
            SetIsGetMenuLoading(false)

        }
        getDataAll()

    },[])

 
    return{
      getMenuData,
      error,
      isGetMenuLoading
    }
}

export const useIsMenuNew = (props) =>{
    //console.log(props.rol,props.path)
    const [isMenuData,SetIsMenuData] = useState(false)
    const[error,SetError] = useState(null)
    const[isMenuLoading,SetIsMenuLoading] = useState(true)
    const[body] = useState({rol:props.rol,path:props.path})

   
    useEffect(()=>{
        async function getDataAll(){
            try{  
                SetIsMenuLoading(true)
                if(props.rol!== undefined ){
                    const response = await axios.post(`/menu/IsMenu/`, body);
                    
                    if(response){
                       
                        SetIsMenuData(response.data)
                        SetIsMenuLoading(false)
                    } 
                } 
                SetIsMenuLoading(false)
            }
            catch(error){
                SetError(error.message)
            }
           

        }
        getDataAll()

    },[body, props.path, props.rol])

 
    return{
      isMenuData,
      error,
      isMenuLoading,
    }
}

export const useIsLoginEnabledNew = () =>{
   
    const [isEnabledData,SetIsEnabledData] = useState([])
    const[error,SetError] = useState(null)
    const[isEnabledLoading,SetIsEnabledLoading] = useState(true)
    const[body,setBody] = useState()


    useEffect(()=>{
        async function getDataAll(){
            try{  
                SetIsEnabledLoading(true)
                if(body!== undefined){
                   
                    const response = await axios.post("enableLogin/enableLogin", body);
                    
                    if(response){
                       
                        SetIsEnabledData(response.data)
                        SetIsEnabledLoading(false)
                        
                    } 
                } 
                 
            }
            catch(error){
                SetError(error.message)
            }
           

        }
        getDataAll()

    },[body])

 
    return{
      isEnabledData,
      error,
      isEnabledLoading,
      setBody,
    }
}

export const useGetLoginEnabledNew = () =>{
    
    const [getLoginEnabledData,SetGetLoginEnabledData] = useState([])
    const[error,SetError] = useState(null)
    const[isGetLoginEnabledLoading,SetIsGetLoginEnabledLoading] = useState(true)

    useEffect(()=>{
        async function getDataAll(){
            try{  
                SetIsGetLoginEnabledLoading(true)
                const response = await axios.post("/enableLogin/getEnableLogin");
                if(response){
                   SetGetLoginEnabledData(response.data)
                    SetIsGetLoginEnabledLoading(false)
                }   
            }
            catch(error){
                SetError(error.message)
            }
            

        }
        getDataAll()

    },[])

 
    return{
      getLoginEnabledData,
      error,
      isGetLoginEnabledLoading
    }
}









