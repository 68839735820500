import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  orders: [],
  currentOrder: {},
  shadowOrder: {},
  pendingOrders: 0,
  newState: '',
};

const slice = createSlice({
  name: "orders",
  initialState,
  reducers: {

    setOrders(state, {payload}) {
      state.orders = payload;
    },

    setPendingOrders(state, {payload}) {
      state.pendingOrders = payload;
    },

    setCurrentOrder(state, {payload}) {
      state.currentOrder = payload;
    },

    deleteOrder(state, {payload}) {
      const orders = {...state.orders};
      const index = orders.data.findIndex(client => client._id === payload);
      orders.data.splice(index, 1);
      state.orders = orders;
    },

    setNewState(state, {payload}) {
      state.newState = payload;
    },

    setShadowOrder(state, {payload}) {
      state.shadowOrder = payload;
    },

  },
});

export const { setOrders, setCurrentOrder, deleteOrder, setPendingOrders, setNewState, setShadowOrder } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


