import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  workers: [],
  currentWorker: {},
};

const slice = createSlice({
  name: "workers",
  initialState,
  reducers: {

    setWorkers(state, {payload}) {
      state.workers = payload;
    },

    setCurrentWorker(state, {payload}) {
      state.currentWorker = payload;
    },

    deleteWorker(state, {payload}) {
      const workers = {...state.workers};
      const index = workers.data.findIndex(client => client._id === payload);
      workers.data.splice(index, 1);
      state.workers = workers;
    }

  },
});

export const { setWorkers, setCurrentWorker, deleteWorker } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


