import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  providers: [],
  currentProvider: {},
};

const slice = createSlice({
  name: "providers",
  initialState,
  reducers: {

    setProviders(state, {payload}) {
      state.providers = payload;
    },

    setCurrentProvider(state, {payload}) {
      state.currentProvider = payload;
    },

    deleteClient(state, {payload}) {
      const providers = {...state.providers};
      const index = providers.data.findIndex(client => client.id === payload);
      providers.data.splice(index, 1);
      state.providers = providers;
    }

  },
});

export const { setProviders, setCurrentProvider, deleteProvider } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


