import {
  removeItemFromCart,
  addItemToCart,
  emptyCart,
  editItemQuantity,
} from "../redux/slices/cart";
import { useDispatch, useSelector } from "../redux/store";

// ----------------------------------------------------------------------

export default function useCart() {
  const { items } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const handleUpdateQuantity = (item) => {
    dispatch(editItemQuantity(item));
  };

  const handleAddItemToCart = (item) => {
    dispatch(addItemToCart(item));
  };

  const handleRemoveItemFromCart = (item) => {
    dispatch(removeItemFromCart(item));
  };

  const handleEmptyCart = () => {
    dispatch(emptyCart());
  };

  function getTotal() {
    let total = 0;
    for (let i = 0; i < items.length; i++) {
      total += items[i].totalAmount;
    }
    return total;
  }

  const cartTotal = getTotal();

  return {
    items,
    cartTotal,
    handleAddItemToCart,
    handleRemoveItemFromCart,
    handleEmptyCart,
    handleUpdateQuantity,
  };
}
