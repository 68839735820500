import { useState, useEffect } from "react";
import { decodeJwt } from "jose";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
// material
import { Box, Badge, Button,Divider,Drawer,IconButton,Toolbar,Typography, FormGroup,FormControlLabel,Switch,} from "@mui/material";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalMallIcon from "@mui/icons-material/LocalMall";
// components
import MenuItems from "./MenuItems";
//hooks
import useAuth from "../hooks/api/auth/useAuth";
import useCart from "../hooks/useCart";
import {usePendingOrdersByState} from "../hooks/api/orders/custom-OrdersNew";
import useCartSidebar from "../hooks/useCartSidebar";
import CartSidebar from "./CartSidebar";
import {useIsLoginEnabledNew} from '../hooks/api/menu/custom-MenusNew'

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 350;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: "100%",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const BadgeStyled = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: 7,
    right: 5,
  },
}));

// ----------------------------------------------------------------------

export default function MainLayout() {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { handleLogout } = useAuth();
  const [open, setOpen] = useState(false);
  const [estdata, setEstdata] = useState();
  const [breadcrumb, setBreadcrumb] = useState("");
  const user = localStorage.getItem("al-currentUser");
  const decodedToken = decodeJwt(user);
  const { items, cartTotal, handleRemoveItemFromCart, handleEmptyCart } = useCart();
  const { handleDrawerToggle, mobileOpen } = useCartSidebar();
  const{ isEnabledData,isEnabledLoading,setBody,} = useIsLoginEnabledNew();
  const navigate = useNavigate();
  const isSellerUser =
    ( decodedToken && decodedToken.rol !== null &&  decodedToken.rol === "Vendedor") ||
    ( decodedToken && decodedToken.rol !== null &&  decodedToken.rol === "Administrador")|| 
    ( decodedToken && decodedToken.rol !== null &&  decodedToken.rol === "Cliente") ;

  const isAdmin =
    decodedToken &&
    decodedToken.rol !== null &&
    decodedToken.rol === "Administrador";
  
  const {pendingOrdersStatedata, isPendingOrdersStateLoading} = usePendingOrdersByState(decodedToken.rol)  
  
  const [canLogin, setCanLogin] = useState(window.localStorage.getItem('canLogin'));

  const [actualPath, setActualPath] = useState("/");

  const handleDrawerChange = () => {
    //limpiar variables localStorage de los modulos locales 
   // window.localStorage.removeItem('queryAdmStorage');
    //abrir
    setOpen(!open);
  };

  function getAmountOfProducts() {
    let length = 0;

    for (let i = 0; i < items.length; i++) {
      length += items[i].quantity;
    }
    return length;
  }

  useEffect(() => {

    if(isPendingOrdersStateLoading === false){    
      const dataest = pendingOrdersStatedata
      setEstdata(dataest)
    } 
 
    if (location.pathname === "/catalogo") {
      setBreadcrumb("Catálogo online");
      setActualPath("/catalogo");
    } else if (location.pathname.includes("pedidosCliente")) {
      setBreadcrumb("Pedidos cliente");
      setActualPath(location.pathname);
    } else if (location.pathname === "/estadisticas") {
      setBreadcrumb("Estadísticas");
      setActualPath(location.pathname);
    } else {
      setBreadcrumb(location.pathname.replace("/", ""));
      setActualPath(location.pathname);
    }


    if(isEnabledLoading===false){
      setCanLogin(isEnabledData.data);
      window.localStorage.setItem('canLogin',isEnabledData.data);
    }

    
  }, [canLogin, isEnabledData.data, isEnabledLoading, isPendingOrdersStateLoading, location, pendingOrdersStatedata]);

  function formatBreadCrumb() {
    return (
      breadcrumb.charAt(0).toUpperCase() + breadcrumb.slice(1).split("/")[0]
    );
  }

  const validPathIcon = () => {
    if (actualPath.includes("pedidosCliente")) {
      return false;
    }
    let ArrActualPath  = actualPath.split('/');
    switch (ArrActualPath[1].toLowerCase()) {
      case "productos":
        return false;
      case "trabajadores":
        return false;
       case "empleados":
        return false;  
      case "clientes":
        return false;
      case "clientes.":
        return false;  
      case "proveedores":
        return false;
      case "pedidos":
        return false;
      case "pedidosCliente":
        return false;
      case "estadisticas":
        return false;
      default:
        return true;
    }
  };

  const isVoucher = location.pathname.includes("/vender/comprobante/");

  const changeLogin = async () => {
    
    setBody({ isLoginEnabled: canLogin === "SI" ? "NO" : "SI" });
   
      enqueueSnackbar(
        `Se ha ${
          canLogin === "SI" ? "deshabilitado" : "habilitado"
        } el inicio de sesión`,
        { variant: "success" }
      );
  };

  return (
    <Box sx={{ display: "-webkit-box" }}>
      <AppBar
        position="fixed"
        className="main-appbar"
        open={open}
        sx={{
          backgroundColor: "#fff",
          display: isVoucher ? "none" : "flex",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className={"quitarImpresion"}
        >
          <Box display="flex" alignItems="center">
            <BadgeStyled
              badgeContent={
                decodedToken &&
                decodedToken.rol !== null &&
                decodedToken.rol !== "Cliente"
                  ? estdata
                  : 0
              }
              color="primary"
            >
              <IconButton
                onClick={handleDrawerChange}
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
              >
                <MenuIcon color="secondary" />
              </IconButton>
            </BadgeStyled>
          </Box>
          <Typography variant="h5" style={{ color: "#434F5F" }}>
            {breadcrumb
              ? formatBreadCrumb()
              : `Bienvenido, ${decodedToken?.name ?? ""}!`}

              
          </Typography>
              <Box>
               <>
               {((isSellerUser && location.pathname === "/vender") || (isSellerUser && location.pathname === "/catalogo")) && (
          
                <>
                  <IconButton onClick={() => handleDrawerToggle() } >
                    <ShoppingCartIcon />
                  </IconButton>
                
                {validPathIcon() === true && (
                  <IconButton
                    sx={{ ml: 1,display: { sm: "inline-flex" }, }}
                    onClick={() => actualPath.includes("catalogo")? navigate("/resumen-de-compras") : navigate("/resumen-de-compra")}
                  >
                    <Badge
                      badgeContent={
                        items.length > 0 ? getAmountOfProducts() : 0
                      }
                      color="primary"
                    >
                      <LocalMallIcon />
                    </Badge>
                  </IconButton>
                )}
                 </> 
               )} 
               </> 
              </Box>
           
            
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        ModalProps={{ keepMounted: true }}
        onClose={handleDrawerChange}
        open={open}
        sx={{
          width: "100%",
          maxWidth: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: DRAWER_WIDTH,
            boxSizing: "border-box",
          },
        }}
      >
        <DrawerHeader
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1.5rem 2rem",
          }}
        >
          <Box>
            <Typography variant="h5">
              {decodedToken && decodedToken.name ? decodedToken.name : ""}
            </Typography>
            <Typography>
              {decodedToken && decodedToken.rol ? decodedToken.rol : ""}
            </Typography>
          </Box>
          <IconButton onClick={handleDrawerChange}>
            <CloseIcon />
          </IconButton>
        </DrawerHeader>

        <Divider />

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <MenuItems fn={() => setOpen(false)} />

          {isAdmin && (
           
            <Box pt={1}>
              <FormGroup>
                <FormControlLabel
                  onChange={changeLogin}
                  sx={{ display: "block", mx: "auto", pr: "12px" }}
                  control={
                    <Switch checked={canLogin === "SI" ? true : false} />
                  }
                  label="Habilitar inicio de sesión"
                />
              </FormGroup>
            </Box>
          )}

          <Box display="flex">
            <Button
              variant="text"
              onClick={handleLogout}
              color="secondary"
              fullWidth
            >
              Cerrar sesión
            </Button>
          </Box>
        </Box>
      </Drawer>
      {((isSellerUser && location.pathname === "/vender") || (isSellerUser && location.pathname === "/catalogo")) && (
        <CartSidebar
          removeItemFromCart={handleRemoveItemFromCart}
          cartTotal={cartTotal}
          getProductsQuantity={getAmountOfProducts}
          products={items}
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          emptyCart={handleEmptyCart}
          actualPath ={actualPath}
        />
      )}
      <Box
        component="main"
        className="wrapper-appbar"
        sx={{
          pt: isVoucher ? "0px" : { xs: "56px", sm: "64px", md: "64px" },
          width: "100%",
          height: "100%",
          minHeight: "100vh",
          flexGrow: 1,
          backgroundColor: "#f7f7f8",
        }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>

      
    </Box>
  );
}
