import { useState } from "react";

// ----------------------------------------------------------------------

const useCartSidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const onToggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prev) => !prev);
  };

  return {
    mobileOpen,
    handleDrawerToggle,
    isCollapsed,
    onToggleCollapse,
  };
};

export default useCartSidebar;
