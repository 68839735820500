import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  query:{

  }
};

const slice = createSlice({
  name: "stats",
  initialState,
  reducers: {

    setQuery(state, {payload}) {
      state.query = payload;
    }

  },
});

export const { setQuery } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
