import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  currentUser: localStorage.getItem('al-currentUser') ? localStorage.getItem('al-currentUser') : null,
  token: localStorage.getItem('al-token') ? localStorage.getItem('token') : null
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {

    setCurrentUser(state, {payload}) {
      state.currentUser= payload;
      localStorage.setItem('al-currentUser', payload)
    },

    setToken(state, {payload}) {
      state.token = payload;
      localStorage.setItem('al-token', payload)
    },

    logout(state, {payload}) {
      state.token = null;
      state.currentUser = null;
      localStorage.removeItem('al-currentUser');
      localStorage.removeItem('al-token');
      localStorage.removeItem('queryStorage');
      
      
    }

  },
});

export const { setToken, setCurrentUser, logout } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


