import {createSlice} from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  items: JSON.parse(localStorage.getItem("cart") || "[]"),
};

const slice = createSlice({
  name: "cart",
  initialState,
  reducers: {

    addItemToCart(state, {payload}) {
      const index = state.items.findIndex(x => x.id === payload.id);
      if (index === -1) {
        state.items.push(payload);
      } else {
        state.items[index].totalAmount += payload.totalAmount;
        state.items[index].quantity += payload.quantity;
        state.items[index].priceSelected = payload.priceSelected;
        state.items[index].priceValueSelected = payload.priceValueSelected;
      }
      localStorage.setItem("cart", JSON.stringify(state.items));
    },

    removeItemFromCart(state, {payload}) {
      //const index = state.items.findIndex(x => x === payload);
      const index = state.items.findIndex(x => x.id === payload.id);
      state.items.splice(index, 1);
      localStorage.setItem("cart", JSON.stringify(state.items));
    },

    emptyCart(state, {payload}) {
      state.items = [];
      localStorage.removeItem("cart");
    },

    editItemQuantity(state, {payload}) {
      const index = state.items.findIndex(x => x.id === payload.id);
      state.items[index].totalAmount = payload.totalAmount;
      state.items[index].quantity = payload.quantity;
      state.items[index].priceSelected = payload.priceSelected;
      state.items[index].priceValueSelected = payload.priceValueSelected;
      localStorage.setItem("cart", JSON.stringify(state.items));
    }

  },
});

export const {addItemToCart, removeItemFromCart, emptyCart , editItemQuantity} = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


