import { useState } from "react";
import { useDispatch, useSelector } from "../../../redux/store";
import { setToken, setCurrentUser, logout } from "../../../redux/slices/auth";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "../../../utils/axios";
import useCart from "../../useCart";
import moment from "moment";

export default function useAuth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, currentUser } = useSelector((state) => state.auth);
  const { handleEmptyCart } = useCart()
  const [loading, setLoading] = useState(false);
  const [canLogin, setCanLogin] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
 

 

  async function login(data) {
    try {
      setLoading(true);
      const responseLog = await axios.get("enableLogin/getLoginEnable");
      const response = await axios.post("auth/login", data);
      if (response.data.success) {
        if ( response.data.rol === "Cliente" && responseLog.data.data.isLoginEnabled === "NO") {
           setCanLogin("NO");
            window.localStorage.setItem("canLogin","NO");
        } else {
          window.localStorage.setItem("canLogin", "SI" );

          const date = new Date();
          const firstDay = moment(new Date(date.getFullYear(), date.getMonth()-3, 1)).format("YYYY-MM-DD" ) + "T00:00:00.000Z";
          const lastDay = moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format( "YYYY-MM-DD" ) + "T23:59:59.000Z";
          let query= { idClient:"", ClientName:[], state:[], stateName:[], search: "", dateFrom: firstDay,dateTo: lastDay,limit: 15,page: 1,rol:response.data.rol,}
          window.localStorage.setItem('queryAdmStorage',JSON.stringify(query));
          
          let queryDash = { startDate: firstDay, endDate: lastDay, categorys: [],  clients: [], providers:[],paymentMethod:[],states:[],limit: 15,page: 1,rol:response.data.rol,view:1}
          window.localStorage.setItem('queryDashStorage',JSON.stringify(queryDash));
          
          setCanLogin("SI");
          dispatch(setToken(response.data.data));
          dispatch(setCurrentUser(response.data.data));
          navigate("/");
          enqueueSnackbar("Bienvenido", { variant: "success" });
        }
      }
    } catch (e) {
      enqueueSnackbar(e.error, { variant: "error" });
    } finally {
      setLoading(false);
    }
  }

  async function sendEmailVerification(data) {
    try {
      setLoading(true);
      const response = await axios.post("auth/password-recovery", data);
      if (response.data.success) {
        enqueueSnackbar(response.data.message, { variant: "success" });
        navigate("/login");
      }
    } catch (e) {
      enqueueSnackbar(e.error, { variant: "error" });
    } finally {
      setLoading(false);
    }
  }

  async function resetPassword(data) {
    try {
      setLoading(true);
      const response = await axios.post("auth/change-password", data);
      if (response.data.success) {
        enqueueSnackbar(response.data.message, { variant: "success" });
        navigate("/login");
      }
    } catch (e) {
      enqueueSnackbar(e.error, { variant: "error" });
    } finally {
      setLoading(false);
    }
  }

  function handleLogout() {
    handleEmptyCart()
    dispatch(logout());
    navigate("/");
  }

  return {
    login,
    canLogin,
    setCanLogin,
    handleLogout,
    token,
    currentUser,
    loading,
    sendEmailVerification,
    resetPassword,
  };
}
