// const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
// const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");
const catchHtml = (html) => {
  return {
    __html: html
  }
}

const formatPrice = (num) => {
  
  if (num) {
    if (typeof num !== "string") {
      return num.toString().replace(/[^0-9]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
      return num.replace(/[^0-9]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  }
}



export { catchHtml, formatPrice }
