import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  products: [],
  currentProduct: {},
};

const slice = createSlice({
  name: "products",
  initialState,
  reducers: {

    setProducts(state, {payload}) {
      state.products = payload;
    },

    setCurrentProduct(state, {payload}) {
      state.currentProduct = payload;
    },

    deleteProduct(state, {payload}) {
      const products = {...state.products};
      const index = products.data.findIndex(client => client._id === payload);
      products.data.splice(index, 1);
      state.products = products;
    }

  },
});

export const { setProducts, setCurrentProduct, deleteProduct } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


