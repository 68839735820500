import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import {ProgressBarStyle} from "./components/LoadingScreen";
import ScrollToTop from "./components/ScrollToTop";
import Router from "./routes";
import { useLocation,} from 'react-router-dom';
import 'react-phone-input-2/lib/material.css'
import { useEffect, useState } from 'react';
import SplashScreen from "./pages/SplashScreen";
import {SnackbarProvider} from 'notistack'
import { useNavigate } from "react-router-dom";
import {useIsMenuNew} from '../src/hooks/api/menu/custom-MenusNew'
import { decodeToken } from "react-jwt";
// ----------------------------------------------------------------------

function App() {


  
  const [component, setComponent] = useState(<SplashScreen />)
  const navigate = useNavigate();
  const location = useLocation();
  const user = localStorage.getItem("al-currentUser") || "Otro";
 
  let decodedToken = ""
  if(user!==""){
   decodedToken = decodeToken(user);
  } 
 
  let rol = "Inicio"
  if(decodedToken){
    rol = decodedToken.rol;
  } 

  let path = location.pathname;
  const{isMenuData,isMenuLoading} = useIsMenuNew({rol,path});
  
  
  useEffect(() => {
   
    if(isMenuLoading===false){
      let permission = isMenuData.data;
      setTimeout(() => {
        setComponent(<Router />)
        if(permission===false){
          navigate('/login-auth')
        }
      }, 2000)
    }
    
  }, [isMenuData, isMenuLoading, location, navigate, rol])

  return (
    <ThemeConfig>
      <GlobalStyles />
      <ProgressBarStyle />
      <ScrollToTop />
        <SnackbarProvider preventDuplicate maxSnack={4}  autoHideDuration={2000} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
          {component}
        </SnackbarProvider>
    </ThemeConfig>
  );
}

export default App;
