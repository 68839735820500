import axios from "axios";

const axiosInstance = axios.create({
  //baseURL:'http://localhost:5001/api/v1/',
  baseURL:'https://backend.alishop.cl/api/v1/',
  headers: { "Access-Control-Allow-Origin": "*" },
  mode: "CORS",
}); 

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Error en la conexión "
    )
);

export default axiosInstance;
