import { useNavigate} from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Button,Box,Stack,Drawer, Typography,IconButton,} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// utils
import { formatPrice } from "../utils/format";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = '30%';
const DRAWER_WIDTHM = '80%';


const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const BoxFooter = styled(Box)(() => ({
  //position: "absolute",
  bottom: 0,
  right: 0,
  width: "100%",
}));

const LinkButton = styled(Button)(({ theme }) => ({
  fontFamily: theme.typography.subtitle1.fontFamily,
  fontSize: theme.typography.body1.fontSize,
  textDecoration: "underline",
  color: theme.palette.secondary.light,
}));

// ----------------------------------------------------------------------

const DrawerCart = ({
  products,
  cartTotal,
  cartQuantity,
  removeItemFromCart,
  emptyCart,
  actualPath,
}) => {
  const navigate = useNavigate();
 
  return (
    <Box height="calc(100vh - 66px)" px={2} pt={2} position="relative">
      {products.map((product) => (
        <Stack direction="row" sx={{ pb: 2 }} key={product.id}>
          <Box width={35} height={30} display="flex" alignItems="center">
            <Typography variant="subtitle1">{`x${product.quantity}`}</Typography>
          </Box>

          <Box width="calc(100% - 140px)" pt="4px">
            <Typography variant="subtitle1">{`${product.name}`}</Typography>
            <Typography
              variant="caption"
              color="GrayText"
              sx={{ textDecoration: "line-through" }}
            >
              {`$ ${formatPrice(product.priceValueSelected)}`}
            </Typography>
          </Box>

          <Box width={75} height={30} display="flex" alignItems="center">
            <Typography variant="subtitle1">{`$ ${formatPrice(
              product.totalAmount
            )}`}</Typography>
          </Box>
          <Box width={30}>
            <IconButton
              size="small"
              onClick={() => removeItemFromCart(product)}
            >
              <DeleteOutlineOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>
        </Stack>
      ))}

      <Stack
        direction="row"
        justifyContent="space-between"
        borderTop="2px solid rgba(145, 158, 171, 0.24)"
        pt={2}
      >
        <Typography variant="subtitle1" color="GrayText">
          {`${cartQuantity} ítems`}
        </Typography>
        <Typography variant="subtitle1" color="GrayText">
          {`Total $ ${formatPrice(cartTotal)}`}
        </Typography>
      </Stack>

      <BoxFooter p={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mb: 1 }}
          onClick={() =>  actualPath.includes("catalogo")? navigate("/resumen-de-compras") : navigate("/resumen-de-compra")}
        >
          {`${cartQuantity} ítems = $ ${formatPrice(cartTotal)}`}
        </Button>
        <LinkButton
          variant="text"
          color="secondary"
          fullWidth
          onClick={emptyCart}
        >
          Vaciar carrito
        </LinkButton>
      </BoxFooter>
    </Box>
  );
};

// ----------------------------------------------------------------------

const EmptyCart = () => {
  return (
    <Box
      height="calc(100vh - 66px)"
      px={2}
      pt={2}
      pb="71px"
      position="relative"
      display="flex"
      alignItems="center"
    >
      <div>
        <Typography variant="subtitle1" align="center" sx={{ mb: 3 }}>
          Escoge los productos que deseas
        </Typography>
        <Box
          component="img"
          src="/assets/img/no-cart-items.jpg"
          sx={{ width: "80%", height: "auto", display: "block", mx: "auto" }}
        />
      </div>

      <BoxFooter px={2}>
        <Box p={3} borderTop="2px solid rgba(145, 158, 171, 0.24)">
          <Typography variant="subtitle1" align="center">
            No hay items seleccionados
          </Typography>
        </Box>
      </BoxFooter>
    </Box>
  );
};

// ----------------------------------------------------------------------

const CartSidebar = (props) => {
  const {
    //window,
    getProductsQuantity,
    products,
    cartTotal,
    removeItemFromCart,
    mobileOpen,
    handleDrawerToggle,
    emptyCart,
    actualPath,
  } = props;

  //const container = window !== undefined ? () => window().document.body : undefined;
  const cartQuantity = getProductsQuantity();

  const drawerContent =
    cartQuantity === 0 ? ( <EmptyCart /> ) : (  <DrawerCart
                                                  products={products}
                                                  cartTotal={cartTotal}
                                                  removeItemFromCart={removeItemFromCart}
                                                  cartQuantity={cartQuantity}
                                                  emptyCart={emptyCart}
                                                  actualPath ={actualPath}
                                                />
    );

  return (
      <Drawer
       // container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        anchor="right"
        ModalProps={{
          keepMounted: true,
        }}
         sx={{
          width: "100%",
          maxWidth: { sm: DRAWER_WIDTHM ,md: DRAWER_WIDTH },
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: { sm: DRAWER_WIDTHM ,md: DRAWER_WIDTH },
            boxSizing: "border-box",
          },
        }}
      >
        <Box
          height="64px"
          borderBottom="6px solid rgba(145, 158, 171, 0.24)"
          display="flex"
          alignItems="center"
          px={2}
        >
          <Stack direction="row" justifyContent="space-between" width='100%' >
            <DrawerHeader
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "1.5rem 2rem",
              }}
            >
              <IconButton size="small" onClick={handleDrawerToggle}>
                <ArrowForwardIosIcon fontSize="small" />
              </IconButton>
              
              <Typography variant="subtitle2" sx={{mt:'2px'}}>Carro Compras</Typography>
            </DrawerHeader>
          </Stack>
        </Box>
        {drawerContent}
      </Drawer> 
    
  );
};

export default CartSidebar;
