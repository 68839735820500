import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  categories: [],
  currentCategory: {},
};

const slice = createSlice({
  name: "categories",
  initialState,
  reducers: {

    setCategories(state, {payload}) {
      state.categories = payload;
    },

    setCurrentCategory(state, {payload}) {
      state.currentCategory = payload;
    },

    deleteCategory(state, {payload}) {
      const categories = {...state.categories};
      const index = categories.data.findIndex(client => client._id === payload);
      categories.data.splice(index, 1);
      state.categories = categories;
    }

  },
});

export const { setCategories, setCurrentCategory, deleteCategory } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


