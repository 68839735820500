import { useState,useEffect } from "react";
import { useJwt } from "react-jwt";
import axios from "../../../utils/axios";

export const useGetOrdersNew = (props) =>{
    
    const [getOrdersdata,SetGetOrdersdata] = useState([])
    const [error,SetError] = useState(null)
    const [isGetOrdersLoading,SetIsGetOrdersLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                  const response = await axios.post("/orders/getOrdersBySate", props);
              
                if(response){
                   SetGetOrdersdata(response.data)
                }    
            }
            catch(error){
                SetError(error.message)
            }
            SetIsGetOrdersLoading(false)

        }
        getDataAll()

    },[props])

 
    return{
      getOrdersdata,
      error,
      isGetOrdersLoading
    }
}

export const useGetOrdersClientNew = (props) =>{
    
    const [getOrdersClientdata,SetGetOrdersClientdata] = useState([])
    const [error,SetError] = useState(null)
    const [isGetOrdersClientLoading,SetIsGetOrdersClientLoading] = useState(true)
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                  const response = await axios.post("/orders/client", props);
              
                if(response){
                   SetGetOrdersClientdata(response.data)
                }    
            }
            catch(error){
                SetError(error.message)
            }
            SetIsGetOrdersClientLoading(false)

        }
        getDataAll()

    },[props])

 
    return{
      getOrdersClientdata,
      error,
      isGetOrdersClientLoading
    }
}

 export const usePendingOrdersAll = () =>{
 
    
    const [pendingOrdersdata,SetPendingOrdersdata] = useState([])
    const[error,SetError] = useState(null)
    const[isPendingOrdersLoading,SetIsPendingOrdersLoading] = useState(true)
    const user = localStorage.getItem("al-currentUser");
    const { decodedToken } = useJwt(user);
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                SetIsPendingOrdersLoading(true)
                const response = await axios.post("/orders/getPending");
                if(response){
                    SetPendingOrdersdata(response.data.data)
                    SetIsPendingOrdersLoading(false)
                } 
            }
            catch(error){
                SetError(error.message)
            }
          

        }
        getDataAll()

    },[decodedToken])

     return{
        pendingOrdersdata,
        error,
        isPendingOrdersLoading
     }
}

export const usePendingOrdersByState = (props) =>{
 
    
    const [pendingOrdersStatedata,SetPendingOrdersStatedata] = useState([])
    const[error,SetError] = useState(null)
    const[isPendingOrdersStateLoading,SetIsPendingOrdersStateLoading] = useState(true)
   
   

    useEffect(()=>{
        async function getDataAll(){
            try{  
                SetIsPendingOrdersStateLoading(true)
               
                   var body ={rol:props};
                    const response = await axios.post(`/orders/getPendingOrdersByState/`,body);
                    if(response){
                        SetPendingOrdersStatedata(response.data.data)
                        SetIsPendingOrdersStateLoading(false)
                    }
               
            }
            catch(error){
                SetError(error.message)
            }
          

        }
        getDataAll()

    },[props])

     return{
        pendingOrdersStatedata,
        error,
        isPendingOrdersStateLoading
     }
}

export const useGetOrdersByIdNew = (props) =>{
    
    const [getOrdersIdData,SetGetOrdersIdData] = useState([])
    const[error,SetError] = useState(null)
    const[isGetOrdersIdLoading,SetIsGetOrdersIdLoading] = useState(true)
  
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                SetIsGetOrdersIdLoading(true)
                if(props !== undefined){  
                    var body ={id:props};
                  // const response = await axios.get(`/orders/${id}`);
                   const response = await axios.post(`/orders/postOrderById`,body);
                  
                    if(response){
                        SetGetOrdersIdData(response.data)
                        SetIsGetOrdersIdLoading(false)
                    } 
                }   
            }
            catch(error){
                SetError(error.message)
            }
        }
        getDataAll()

    },[props])

    return{
      getOrdersIdData,
      error,
      isGetOrdersIdLoading
    }
}

export const useCreateOrdersNew = () =>{
    
    const [createOrdersdata,SetCreateOrdersdata] = useState([])
    const[error,SetError] = useState(null)
    const[isCreateOrdersLoading,SetIsCreateOrdersLoading] = useState(true)
    const[body,Setbody] = useState()
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                SetIsCreateOrdersLoading(true)
               
                if(body !== undefined){  
                    
                    const response = await axios.post(`/orders/create`, body);
                    
                    if(response){
                        SetCreateOrdersdata(response.data)
                        SetIsCreateOrdersLoading(false)
                    } 
                    
                }  
            }
            catch(error){
                SetError(error.message)
            }
           

        }
        getDataAll()

    },[body])

 
    return{
      createOrdersdata,
      error,
      isCreateOrdersLoading,
      Setbody
    }
}

export const useDeleteOrderNew = () =>{
    
    const [deleteOrderdata,SetDeleteOrderdata] = useState([])
    const[error,SetError] = useState(null)
    const[isDeleteOrderLoading,SetIsDeleteOrderLoading] = useState(true)
    const[bodyId,SetBodyId] = useState()
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
                SetIsDeleteOrderLoading(true)
                if(bodyId!==undefined){

                    const id = bodyId;
                    const response = await axios.delete(`/orders/${id}`);
                    if(response){
                     SetDeleteOrderdata(response.data)
                     SetIsDeleteOrderLoading(false)
                    } 
                }  
            }
            catch(error){
                SetError(error.message)
            }
           
        }
        getDataAll()

    },[bodyId])

 
    return{
      deleteOrderdata,
      error,
      isDeleteOrderLoading,
      SetBodyId
    }
}

export const useUpdateOrderNew = () =>{
    
    const [updateOrderdata,SetUpdateOrderdata] = useState([])
    const[error,SetError] = useState(null)
    const[isUpdateOrderLoading,SetIsUpdateOrderLoading] = useState(true)
    const[bodyUp,setBodyUp] = useState()
    const[OrderIdUp,setOrderIdUp] = useState()
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
               
               SetIsUpdateOrderLoading(true)
              
                if(bodyUp !== undefined && OrderIdUp !== undefined){  
                   const Orderid = OrderIdUp;
                    const response = await axios.put(`/orders/${Orderid}`, bodyUp);
                    //console.log(response.data.success)
                    //if(response.data.success){
                        SetUpdateOrderdata(response.data)
                        SetIsUpdateOrderLoading(false)
                    //} 
                }   
            }
            catch(error){
                SetError(error.message)
            }
           

        }
        getDataAll()

    },[bodyUp, OrderIdUp])

 
    return{
      setBodyUp,
      setOrderIdUp,
      updateOrderdata,
      error,
      isUpdateOrderLoading
    }
}

export const useUpdateOrderRealNew = () =>{
    
    const [updateOrderRealdata,SetUpdateOrderRealdata] = useState([])
    const[error,SetError] = useState(null)
    const[isUpdateOrderRealLoading,SetIsUpdateOrderRealLoading] = useState(true)
    const[bodyRealUp,setBodyRealUp] = useState()
    const[OrderRealIdUp,setOrderRealIdUp] = useState()
    
    useEffect(()=>{
        async function getDataAll(){
            try{  
               
               SetIsUpdateOrderRealLoading(true)
                if(bodyRealUp !== undefined && OrderRealIdUp !== undefined){  
                   const Orderid = OrderRealIdUp;
                    const response = await axios.put(`/orders/updateOrderRealizado/${Orderid}`, bodyRealUp);
                    //console.log(response.data.success)
                    //if(response.data.success){
                        SetUpdateOrderRealdata(response.data)
                        SetIsUpdateOrderRealLoading(false)
                    //} 
                }   
            }
            catch(error){
                SetError(error.message)
            }
           

        }
        getDataAll()

    },[OrderRealIdUp, bodyRealUp])

 
    return{
      setBodyRealUp,
      setOrderRealIdUp,
      updateOrderRealdata,
      error,
      isUpdateOrderRealLoading
    }
}

export const useUpdateOrderProductsNew = () =>{
    
    const [updateOrderPrdata,SetupdateOrderPrdata] = useState([])
    const[error,SetError] = useState(null)
    const[isUpdateOrderPrLoading,SetIsUpdateOrderPrLoading] = useState(true)
    const[bodyPrUp,setBodyPrUp] = useState()
    const[idOrderUp,setIdOrderUp] = useState()

    
    useEffect(()=>{
        async function getDataAll(){
            try{  
               
               SetIsUpdateOrderPrLoading(true)
                if(bodyPrUp !== undefined && idOrderUp !== undefined){  
                   const Orderid = idOrderUp;
                   
                    const response = await axios.put(`/orders/updateOrderProducts/${Orderid}`, bodyPrUp);
                    
                    if(response){
                        SetupdateOrderPrdata(response.data)
                        SetIsUpdateOrderPrLoading(false)
                    }
                  
                }   
            }
            catch(error){
                SetError(error.message)
            }
           

        }
        getDataAll()

    },[bodyPrUp, idOrderUp])

 
    return{
      setBodyPrUp,
      setIdOrderUp,
      updateOrderPrdata,
      error,
      isUpdateOrderPrLoading
    }
}

export const useValidateOrderState = () =>{
   
    const [getValidateOrderStateData,SetValidateOrderStateData] = useState([])
    const[error,SetError] = useState(null);
    const[isValidateOrderStateLoading,SetIsValidateOrderStateLoading] = useState(true);
    const [states,setStates]=useState();
    const [idOrder,setIdOrder]=useState();
    
    useEffect(()=>{
      
        async function getDataAll(){
            try{
                SetIsValidateOrderStateLoading(true) 
               // console.log(isValidateOrderStateLoading,rut)
                if(states !== undefined){ 
                   
                    let orderId = idOrder;
                    let state = states;
                    const response = await axios.post(`/orders/getValidateStateOrder`, { state,orderId });
                   
                    if (response.data) {
                        SetValidateOrderStateData(response.data)
                        SetIsValidateOrderStateLoading(false);
                    }
                }  
            }
            catch(error){
                SetError(error.message)
                console.log(error.message)
            }
            
        }
        getDataAll()

    },[idOrder, isValidateOrderStateLoading, states])

    return {getValidateOrderStateData,
            isValidateOrderStateLoading,
            error,
           setStates,
           setIdOrder};
}









