import {Typography,Divider,List,ListItemButton,ListItemIcon,ListItemText,} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import workers from "../assets/icons/workers.svg";
import clients from "../assets/icons/clients.svg";
import providers from "../assets/icons/providers.svg";
import catalogue from "../assets/icons/catalogue.svg";
import sell from "../assets/icons/sell.svg";
import products from "../assets/icons/products.svg";
import stadistics from "../assets/icons/stadistics.svg";
import stadisticsImg from "../assets/icons/help.svg";
import Box from "@mui/material/Box";
import { decodeToken } from "react-jwt";
import {usePendingOrdersByState} from "../hooks/api/orders/custom-OrdersNew";

// ----------------------------------------------------------------------

const data = [
  {
    title: "Vender",
    path: "/vender",
    value: "vender",
    img: sell,
    key: 1,
    key2: 100,
    key3: 1000,
    rols: ["Administrador", "Vendedor"],
  },
  {
    title: "Catálogo online",
    path: "/catalogo",
    value: "catalogo",
    img: catalogue,
    key: 2,
    key2: 200,
    key3: 2000,
    rols: ["Cliente", "Administrador"],
  },
  {
    title: "Historial de pedidos",
    path: "/pedidosCliente",
    value: "pedidos-de-cliente",
    img: products,
    key: 3,
    key2: 300,
    key3: 3000,
    rols: ["Cliente"],
  },
  {
    title: "Pedidos",
    path: "/pedidos",
    img: products,
    value: "pedidos",
    key: 4,
    key2: 400,
    key3: 4000,
    rols: ["Vendedor", "Administrador", "Transportista", "Bodeguero", "Embalador"],
  },
  {
    title: "Productos",
    path: "/productos",
    img: products,
    value: "productos",
    key: 5,
    key2: 500,
    key3: 5000,
    rols: ["Administrador"],
  },
  {
    title: "Clientes",
    path: "/clientes",
    value: "clientes",
    img: clients,
    key: 6,
    key2: 600,
    key3: 6000,
    rols: ["Administrador", "Vendedor"],
  },
  {
    title: "Proveedores",
    path: "/proveedores",
    value: "proveedores",
    img: providers,
    key: 7,
    key2: 700,
    key3: 7000,
    rols: ["Administrador"],
  },
  {
    title: "Estadisticas",
    path: "/estadisticas",
    img: stadistics,
    value: "estadisticas",
    key: 8,
    key2: 800,
    key3: 8000,
    rols: ["Administrador"],
  },
  {
    title: "Trabajadores",
    path: "/Empleados",
    img: workers,
    value: "Empleados",
    key: 9,
    key2: 900,
    key3: 9000,
    rols: ["Administrador"],
  },
];

// ----------------------------------------------------------------------

export default function MenuItems({ fn }) {
   //const { pendingOrdersdata,isPendingOrdersLoading} = usePendingOrdersNew();
  const user = localStorage.getItem("al-currentUser");
  const decodedToken = decodeToken(user)
  const {pendingOrdersStatedata, isPendingOrdersStateLoading} = usePendingOrdersByState(decodedToken.rol)
  const [estdata, setEstdata] = useState();
   
  const { pathname } = useLocation();
  const navigate = useNavigate();
  
 
  //console.log(decodedToken.rol);
  const goTo = (path) => {
    fn();
    navigate(path);
  };

  function formatOrders(num) {
    if (num < 10) {
      return `0${num}`;
    } else {
      return num;
    }
  }


  useEffect(() => {
      
    if(isPendingOrdersStateLoading === false){    
      const dataest = pendingOrdersStatedata
      setEstdata(dataest)
    } 
  }, [pathname, user, isPendingOrdersStateLoading, pendingOrdersStatedata]);

  const menuDesabilitado = (route) => {
    if (route.path === "/catalogo") {
      if (decodedToken.rol === "Cliente") {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
    <List sx={{ py: 0 }} key={5000}>
      {
        data.map((item) =>(
          <div key={item.key}>
          {
            //menu de pedido
            data.filter(subitem => subitem.rols?.includes(decodedToken.rol) && subitem.value === "pedidos" && subitem.value === item.value ).map(route => (
        
                  <div key={item.key}>
                    <ListItemButton
                      key={route.key2}
                      onClick={() => {
                        goTo(
                          route.path === "/pedidosCliente"
                            ? `${route.path}/${decodedToken.id}`
                            : route.path
                        );
                      }}
                      sx={{
                        minHeight: 48,
                        justifyContent: "initial",
                        borderLeftWidth: "5px",
                        borderLeftStyle: "solid",
                        borderLeftColor:
                          pathname === route.path ? "#EF6800" : "#fff",
                        "&:hover": {
                          borderLeftColor: " #EF6800",
                        },
                        p: 2.5,
                      }}
                    >
                    <ListItemIcon
                      key={route.key3}
                      sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant={"h1"}
                        style={{
                          backgroundColor: "#7E92AD",
                          borderRadius: 5,
                          color: "white",
                          fontSize: 22,
                          padding: 8,
                        }}
                      >
                        {formatOrders(estdata)}
                      </Typography>
                    </ListItemIcon>
                    <ListItemText primary={route.title} />
                  </ListItemButton>
                  <Divider />
                </div>
                
            )) 
          }
          
          {
            //resto de los botones
            data.filter(subitem => subitem.rols?.includes(decodedToken.rol) && subitem.value!==decodedToken.rol&& subitem.value !== "pedidos" && subitem.value === item.value).map(route => (
        
                <div key={item.key}>
                  <ListItemButton
                    key={route.key2}
                    disabled={menuDesabilitado(route)}
                   
                    onClick={() => {
                      goTo(
                        route.path === "/pedidosCliente"
                          ? `${route.path}/${decodedToken.id}`
                          : route.path
                      );
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: "initial",
                      borderLeftWidth: "5px",
                      borderLeftStyle: "solid",
                      borderLeftColor:
                        pathname === route.path ? "#EF6800" : "#fff",
                      "&:hover": {
                        borderLeftColor: " #EF6800",
                      },
                      p: 2.5,
                    }}
                  >
                    <ListItemIcon
                      key={route.key3}
                      sx={{
                        minWidth: 0,
                        mr: 3,
                        justifyContent: "center",
                      }}
                    >
                      <Box component="img" src={route.img} />
                    </ListItemIcon>
                    <ListItemText primary={route.title} />
                  </ListItemButton>
                  <Divider />
                </div>
                
            ))
          }
          </div>
         
        ))
          

      
      }

      <>
        {//menu HELP
          decodedToken.rol !== "Cliente" && (
          <ListItemButton
            key={999}
            onClick={() => {
              const link = document.createElement("a");

              switch (decodedToken.rol) {
                case "Administrador":
                  link.href = `./PDFs/Manual_de_uso_Admin.pdf`;
                  link.download = `./PDFs/Manual_de_uso_Admin.pdf`;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  break;
                case "Bodeguero":
                  link.href = `./PDFs/Manual_de_uso_Bodeguero-_Transportista-_Packing.pdf`;
                  link.download = `./PDFs/Manual_de_uso_Bodeguero-_Transportista-_Packing.pdf`;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  break;
                case "Transportista":
                  link.href = `./PDFs/Manual_de_uso_Bodeguero-_Transportista-_Packing.pdf`;
                  link.download = `./PDFs/Manual_de_uso_Bodeguero-_Transportista-_Packing.pdf`;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  break;
                case "Vendedor":
                  link.href = `./PDFs/Manual_de_uso_Vendedor.pdf`;
                  link.download = `./PDFs/Manual_de_uso_Vendedor.pdf`;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  break;
                case "Embalador":
                  link.href = `./PDFs/Manual_de_uso_Bodeguero-_Transportista-_Packing.pdf`;
                  link.download = `./PDFs/Manual_de_uso_Bodeguero-_Transportista-_Packing.pdf`;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  break;
                default:
                  break;
              }
            }}
            sx={{
              minHeight: 48,
              justifyContent: "initial",
              borderLeftWidth: "5px",
              borderLeftStyle: "solid",
              borderLeftColor: "#fff",
              "&:hover": {
                borderLeftColor: " #EF6800",
              },
              p: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 3,
                justifyContent: "center",
              }}
            >
              <Box component="img" style={{ width: 36 }} src={stadisticsImg} />
            </ListItemIcon>
            <ListItemText primary={"Ayuda"} />
          </ListItemButton>
        )}
        <Divider />
      </>

    </List>
    </>
  );
}
