import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import authReducer from "./slices/auth";
import cartReducer from "./slices/cart";
import clientsReducer from "./slices/clients";
import providersReducer from "./slices/providers";
import workersReducer from "./slices/workers";
import productsReducer from "./slices/products";
import categoriesReducer from "./slices/categories";
import ordersReducer from "./slices/orders";
import statsReducer from "./slices/stats";
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  clients: clientsReducer,
  providers: providersReducer,
  workers: workersReducer,
  categories: categoriesReducer,
  products: productsReducer,
  orders: ordersReducer,
  stats: statsReducer
});

export { rootPersistConfig, rootReducer };
