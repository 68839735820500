import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  clients: [],
  currentClient: {},
};

const slice = createSlice({
  name: "clients",
  initialState,
  reducers: {

    setClients(state, {payload}) {
      state.clients = payload;
    },

    setCurrentClient(state, {payload}) {
      state.currentClient = payload;
    },

    deleteClient(state, {payload}) {
      const clients = {...state.clients};
      const index = clients.data.findIndex(client => client._id === payload);
      clients.data.splice(index, 1);
      state.clients = clients;
    }

  },
});

export const { setClients, setCurrentClient, deleteClient } = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


